import React from "react"

import Layout from "../components/layout-fr"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const ContactsPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Contact</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <div class="content>">
            Réserves et autres questions:
            <br />
            <br />
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:campingsilveira@gmail.com">
                &nbsp;campingsilveira@gmail.com
              </a>
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 917 741 173
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 962 387 266
            </h1>
            <p><strong>La réception est ouverte de 9:00 à 20:00.</strong></p>
            <br></br>
            <p>
              En haute saison, il est préférable d’arriver en début d’après-midi
              pour être certain d’avoir une place. Notre nombre d’emplacements
              est limité, le camping est vite complet et nous n’acceptons de
              réservation que pour une durée minimum d’une semaine.{" "}
            </p>
            <br />
            <p>
              Le camping Silveira est recommandé par de nombreux guides
              internationaux dont le Guide du routard, le Petit futé, le Guide
              Vert Michelin, SVR, Rustiek Kamperen et ANWB.{" "}
            </p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default ContactsPage
